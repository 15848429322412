// font style poppins

//500
@font-face {
    font-family: poppins;
    src: url(../../../assets/fonts/Poppins-Regular.ttf) format("opentype");
  }
  @font-face {
    font-family: poppins-medium;
    src: url(../../../assets/fonts/Poppins-Medium.ttf) format("opentype");
  }
  //600
  @font-face {
    font-family: poppins-semibold;
    src: url(../../../assets/fonts/Poppins-SemiBold.ttf) format("opentype");
  }
  
  @font-face {
    font-family: poppins-bold;
    src: url(../../../assets/fonts/Poppins-Black.ttf) format("opentype");
  }
  
  @font-face {
    font-family: poppins-light;
    src: url(../../../assets/fonts/Poppins-Light.ttf) format("opentype");
  }
  
  .ff-poppins {
    font-family: poppins !important;
  }
  
  .ff-poppins-bold {
    font-family: poppins-bold !important;
  }
  
  .ff-poppins-semibold {
    font-family: poppins-semibold !important;
  }
  
  .ff-poppins-light {
    font-family: poppins-light !important;
  }

  .ff-poppins-regular {
    font-family: poppins-medium !important;
  }