//coach-dashboard

.dashboard-main-div {
  height: 100vh;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 0px;
}

.coach-sm-heading {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
  color: #0b0b0b;
}

.coach-name-heading {
  font-family: "poppins-semibold";
  font-size: 22px;
  line-height: 36px;
  color: #0b0b0b;
}

.move-scroll {
  position: relative;
  right: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
}

.move-scroll-left {
  z-index: 10;
  transform: rotate(180deg);
  position: relative;
  left: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
}

.focus-scroll-div {
  display: flex;
  //  justify-content: space-between;
  overflow: auto;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-x: auto;
  scroll-behavior: smooth;
}

.focus-scroll-div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.focus-item {
  width: 155px;
  height: 200px;
  //box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.01);
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  scale: 0.97;
}

.focus-item:hover {
  scale: 1;
  box-shadow: 2px 00px 10px rgba(0, 0, 0, 0.05);
  //border-radius: 30px;
}

.scroll-card {
  width: 175px;
  height: 230px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.01);
  border-radius: 15px;
  padding: 10px;
}

.scroll-card-heading {
  font-family: "poppins-semibold";
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  color: #222c34;
}

.exercise-athlete-div {
  flex-direction: row;
  display: flex;
  margin-left: 25px;
  margin-right: 20px;
}

.workout-section {
  width: 50%;
  background: #ffffff;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;
}

.athletes-section {
  width: 50%;
  background: #ffffff;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;
}

.workout-heading {
  font-family: "poppins-semibold";
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #222c34;
}

.view-all {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #222c34;
  cursor: pointer;
}

.spinner-workout {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.exercise-sec {
  background: #eef8fe;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.05s ease-in-out;
}

.exercise-sec:hover {
  scale: 1;
  box-shadow: 2px 00px 10px rgba(0, 0, 0, 0.05);
}

.exercise-heading {
  font-family: "poppins-semibold";
  font-size: 14px;
  line-height: 15px;
  color: #0a364b;
}

.exercise-desc {
  font-family: "Poppins";
  font-size: 12px;
  color: #526b78;
  display: block;
}

.exercise-desc-2 {
  font-family: "Poppins";
  font-size: 12px;
  color: #526b78;
}

.exercise-desc-playlist {
  font-family: "Poppins";
  font-size: 12px;
  color: #526b78;
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 7.2em;
  line-height: 1.8em;
}

.assign-btn {
  height: 40px;
  position: unset;
  background: #f79a28;
  box-shadow: 1px 1px 2px rgba(71, 42, 6, 0.1);
  border-radius: 10px;
  font-family: "poppins-semibold";
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-style: none;
}

.border-btn {
  height: 40px;
  background-color: white;
  color: #f79a28 !important;
  border: 1px solid #f79a28;
  font-family: "poppins-semibold";
  font-size: 14px;
}

.border-btn:hover {
  background-color: #f79a28;
  border-color: #f79a28 !important;
  color: #ffffff !important;
}

.arrow-div {
  height: 26px;
  width: 26px;
  background: #0081c6;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
}

.athlete-profile-div {
  display: flex;
  align-items: center;
  height: 70px;
  background: #f6fcff;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.1s ease-in-out;
}

.athlete-profile-div:hover {
  box-shadow: 2px 00px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.athlete-group-card {
  background: #eef8fe;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.11);
}

.athlete-name {
  font-family: "poppins-medium";
  font-size: 14px;
  line-height: 22px;
  color: #0a364b;
}

.activity {
  font-family: "poppins-semibold";
  font-size: 20px;
  margin-left: 10px;
  line-height: 27px;
  color: #0b0b0b;
}

.activity-scroll-div {
  height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.activity-loader {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.activity-scroll-div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.feed-div {
  background: #fbfdff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: auto;
}

.timer {
  font-family: "Poppins";
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}

.feed-desc {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 18px;
  color: #0c0c0c;
}

.feed-heart-icon {
  height: "20px";
  transition: all 0.1s ease-in-out;
}

.feed-heart-icon:hover {
  height: "20px";
  cursor: pointer;
  scale: 1.2;
}

//athlete

.content-athlete-dashboard {
  padding: 25px;
  // height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */

  .team-div {
    position: relative;
  }
}

.content-athlete-dashboard::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.dashboard-widgets-div {
  display: grid;
  grid-template-columns: 25% 25% 46%;
  grid-gap: 15px;
}

.widget {
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 20px;

  .ant-picker-calendar .ant-picker-panel {
    border-top: none;
  }
}

.widget-heading {
  font-family: "poppins-medium";
  font-size: 16px;
  color: #0b0b0b;
}

.widget-value {
  font-family: "poppins-semibold";
  font-size: 36px;
  color: #0b0b0b;
}

.achivement-text {
  font-family: "poppins";
  font-size: 10px;
  color: #0b0b0b;
}

.workout-calendar-div {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 15px;

  .ant-picker-calendar
    .ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner {
    background-color: #f79a28;
    border-radius: 50%;
    height: 27px;
    width: 27px;
  }

  .ant-picker-calendar
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    // border: 1px solid #f79a28;
    border-style: none;
  }
}

.select-year .ant-select-selector {
  border-radius: 24px;
  background-color: #0081c6 !important;
  height: 40px !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  
  .ant-select-selection-item {
    color: #ffffff !important;
    display: flex;
    justify-content: center;
  }
  .ant-select-arrow {
    color: #ffffff;
  }
}

.select-year .ant-select-arrow {
  color: #ffffff;
}

.arrow-div:disabled {
  opacity: 0.4;
}

.round-padding {
  padding-right: 25px !important;
}

.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #d9d9d9;
  border: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #f89728;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active {
  width: 16px;
}

.widget .ant-picker-calendar {
  line-height: 3.1;
}

.ellipsis-string {
  height: 30px;
  padding: 0 10px;
  max-width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}
.stripe-modal-heading {
  font-family: "poppins-semibold";
  font-size: 24px;
  color: #0a364b;
}

.stripe-blue-div {
  background: #eef8fe;
  border: 1px solid #0081c6;
  border-radius: 10px;
  padding: 20px;

  .basic {
    font-family: "poppins-semibold";
    font-size: 15px;
    text-transform: capitalize;
    color: #0a364b;
  }
  .dollar-month {
    font-family: "poppins";
    font-size: 16px;
  }

  .un-order-list {
    padding-left: 20px !important;
  }
}

.stripe-card {
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);
}
