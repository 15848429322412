.dropdown-item {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 26px;
  color: #526b78;
}

.exercise-title-desc {
  font-family: "Poppins";
  font-size: 16px;
  color: #526b78;
  margin-bottom: 0px;
}

.save-workout-btn {
  width: 30%;
}

.exercises-scroll-div {
  height: 50vh;
  overflow-x: auto;
}

.exercises-scroll-div::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
  background-color: transparent;
}
.exercises-scroll-div::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

.exercise-checkbox {
  .ant-checkbox .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    background-color: #c9ecff;
    border-color: #007cba;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #007cba !important;
    margin: 0 2px !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-color: #c9ecff !important;
  }
}

.add-to-feed {
  .ant-checkbox .ant-checkbox-inner {
    border-color: #007cba;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #007cba !important;
  }
}

//Athlete

.upcoming-live {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  color: #007cba;
}
.created-by-coaches {
  font-family: "poppins-semibold";
  font-size: 20px;
  color: #007cba;
}

.live-broadcast {
  font-family: "poppins-semibold";
  font-size: 12px;
  text-transform: capitalize;
  color: #fd4b1d;
}

.workout-head {
  font-family: "poppins-semibold";
  font-size: 20px;
  line-height: 30px;
  color: #0a364b;
}

.how-difficult {
  font-family: "Poppins";
  font-size: 14px;
  color: #617884;
}

.like-btn {
  background-color: #0081c6;
  height: 50px;
  display: flex;
  justify-content: space-around !important;
  width: 45%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.dislike-btn {
  height: 50px;
  display: flex;
  justify-content: space-around !important;
  width: 45%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0081c6 !important;
  border-style: none;
}

.workout-side-div {
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 20px;
  overflow: scroll;
}

.workout-side-div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.workout-detail-div{
  height: 100vh;
  overflow: scroll;
}

.workout-detail-div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.change-workout-date{
  font-family: 'Poppins';
font-size: 15px;
color: #0081C6;
cursor: pointer;
}

.congratulation{
  font-family: 'poppins-medium';
font-size: 20px;
color: #526B78;
}

.congrats-desc{
  font-family: 'poppins-medium';
font-size: 14px;
width: 300px;
text-align: center;
color: #526B78;
}