.exercise-main-div {
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.back-arrow {
  display: flex;
  align-items: center;
  font-family: poppins;
}

.back-arrow:hover {
  cursor: pointer;
}

.exercise-radio > .ant-radio-button-wrapper-checked {
  background-color: #0081c6 !important;
}

.filter-card {
  width: 350px;
  background-color: #f1faff;
  color: #567e95;
  margin-top: 20px;
  border-bottom: 10px solid #ffffff;
}

.filter-card-heading {
  background-color: #79a1b7;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  margin: -16px 0px;
}

.filter-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(156, 187, 204, 0.25);

  .ant-checkbox .ant-checkbox-inner {
    border-radius: 50%;
  }

  .ant-checkbox-checked:after {
    border: none !important;
  }

  .ant-checkbox .ant-checkbox-inner {
    height: 25px;
    width: 25px;
    background-color: #c9ecff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c9ecff !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #007cba !important;
    margin: 0 3px !important;
  }
}
