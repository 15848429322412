.fav-radio .ant-radio-group-solid>.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: #0081c6;
    border-radius: 25px !important;
  }
  
  .fav-radio .ant-radio-group-solid>.ant-radio-button-wrapper-checked:hover {
    color: #fff;
    background-color: #0081c6;
    border-radius: 25px !important;
  }