.notification-dropdown {
  width: 500px !important;

  .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 5px 0px !important;
  }

  .ant-dropdown-menu {
    padding: 0px;
  }
}

.notification-heading-div {
  border-bottom: 0.5px solid #f4f4f5;
  padding: 5px 0px !important;
}

.notification-heading {
  font-family: "poppins-semibold";
  font-size: 18px;
  color: #0b0b0b;
}

.single-notification {
  display: grid;
  grid-template-columns: 7fr 2fr;
  grid-gap: 60px;
  border-bottom: 0.5px solid #f4f4f5;
  padding: 10px;
}

.notification-description {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #667085;
}

.notification-time-ago {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.25px;
  color: #667085;
}

.view-more {
  font-family: "poppins-semibold";
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #222c34;
}

.notification-page-heading {
  font-family: "poppins-semibold";
  font-size: 20px;
  line-height: 27px;
  color: #0b0b0b;
}

.single-notification-on-page {
  display: grid;
  grid-template-columns: 5fr 7fr;
  border-bottom: 0.5px solid #f4f4f5;
  padding: 10px;
}
