@import url(./fonts/fonts.scss);
@import "toastr";
@import url(./Auth.scss);
@import url(./Dashboard.scss);
@import url(./Group.scss);
@import url(./AthleteProfile.scss);
@import url(./AssignModal.scss);
@import url(./Workout.scss);
@import url(./Exercises.scss);
@import url(./Notification.scss);
@import url(./Favorite.scss);

.ant-form-item-explain-error {
  text-align: left;
}
.button {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.filled-blue-btn {
  box-shadow: 1px 1px 2px rgba(71, 42, 6, 0.1);
  border-radius: 10px;
  height: 40px;
  font-family: "poppins-semibold";
  font-size: 15px;
  background-color: #0081c6;
}

.border-blue-btn {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  color: #0081c6 !important;
  background-color: #ffffff;
  height: 40px;
  border: 2px solid #0081c6;
  filter: drop-shadow(1px 1px 2px rgba(71, 42, 6, 0.1));
}

.assign-text{
  font-size: 16px;
  line-height: 24px;
  font-family: "poppins-semibold";
}

.border-red-btn {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  color: #ff0000 !important;
  background-color: #ffffff;
  height: 45px;
  border: 2px solid #ff0000;
  filter: drop-shadow(1px 1px 2px rgba(71, 42, 6, 0.1));
}

.border-red-btn:hover {
  border: 2px solid #ff0000 !important;
}

.border-yellow-btn {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  color: #f79a28 !important;
  background-color: #ffffff;
  height: 40px;
  border: 2px solid #f79a28;
  filter: drop-shadow(1px 1px 2px rgba(71, 42, 6, 0.1));
}
.border-yellow-btn:hover {
  border-color: #f79a28 !important;
}

.ant-input::placeholder {
  color: #526b78;
}

.ant-form-item {
  margin: 0;
}

/* The alert message box */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

//spinner
.ant-spin .ant-spin-dot-item {
  background-color: #ffffff;
}

//sider

.ant-menu-item {
  font-family: "poppins-semibold";
  border-radius: 50px !important;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  margin-bottom: 20px !important;
  display: flex;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: 
  rgb(169 168 168 / 6%) !important;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-style: none;
}

.ant-menu-inline .ant-menu-item {
  height: 45px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  display: block;
  justify-items: center;
  height: 45px;
  width: 55px;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  vertical-align: middle;
  width: 20px;
  margin-left: -2.1px;
  object-fit: contain;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

.ant-menu-light .ant-menu-item-selected {
  background: #0081c6;
  box-shadow: 0px 1px 5px rgba(38, 51, 77, 0.03);
  border-radius: 50px;
  color: #ffffff;
}

.ant-menu-item {
  margin-top: 1rem !important;
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: 30px !important;
  position: unset !important;
}

.ant-layout-sider-children {
  overflow: auto;
  padding-inline: 7px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
.ant-layout-sider-children::-webkit-scrollbar {
  width: 0.2em;
  background-color: transparent;
}

.ant-layout-sider-children::-webkit-scrollbar-track {
  background-color: transparent;
}

.ant-layout-sider-children::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25em;
}
.ant-layout-sider-children::-webkit-scrollbar-button {
  height: 5em;
}
.ant-layout-sider-children::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.ant-drawer-body {
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
.ant-drawer-body::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.ant-drawer-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25em;
}

.ant-drawer-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline {
  width: 175px;
}

.ant-modal-confirm .ant-modal-confirm-body {
  flex-direction: column;
}

.ant-modal-confirm-content {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 27px;
  color: #0a364b;
  margin-top: 10px;
}

.line-height-51{
  line-height: 51px;
}
//modal

.ant-modal {
  width: max-content !important;
}
.form-modal {
  width: max-content;

  .ant-modal-close {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }
}

.modal-field {
  width: 100%;
  background: #ffffff;
  box-shadow: -1px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  height: 50px;
  border: none;
}

.modal-btn {
  background: #f79a28;
  box-shadow: 1px 1px 2px rgba(71, 42, 6, 0.1);
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 27px;
}

.modal-cross {
  height: 24px;
  position: absolute;
  top: 9px;
  right: 7px;
}

.profile-dropdown {
  width: 250px !important;
}

.profile-main-div {
  border: 1px solid #eef8fe;
  border-radius: 10px;
  width: 520px;
}

.profile-btn > span {
  padding-left: 7px;
}

.ant-upload-list {
  display: none;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
}

.faq-sub-heading {
  font-family: "poppins-medium";
  font-size: 16px;
  color: #007cba;
}

.faq-div {
  border-bottom: 1px solid rgba(0, 124, 186, 0.15);
  cursor: pointer;
}

.faq-bold {
  font-family: "poppins-semibold";
}

.faq-blue-underline {
  color: rgb(4, 98, 193);
  text-decoration: underline;
}

.profile-disabilities-div {
  border: 1px solid #eef8fe;
  border-radius: 10px;

  .ant-switch {
    border-radius: 5px;
  }

  .ant-switch .ant-switch-handle::before {
    border-radius: 5px;
  }

  .ant-switch.ant-switch-checked {
    background: #dcf3ff;
  }

  .ant-switch:hover:not(.ant-switch-disabled) {
    background: rgba(57, 126, 193, 0.1);
  }
}

.hide-menu-item {
  display: none !important;
}

//=====================raza work space==================
.invite-heading {
  font-family: "poppins-semibold";
  font-size: 20px;
}
.invite-link-des {
  font-family: "poppins-semibold";
  font-size: 14px;
}
.invite-friend {
  height: 20px;
  margin-right: 5px;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: white !important;
  pointer-events: none;
}
.img_log_workout_success-desc {
  text-align: justify;
}
.edit-profile-input-div {
  display: flex;
  width: 100%;
}
.edit-profile-input-div .ant-form-item {
  width: 100% !important;
}
.edit-profile-input {
  width: 100%;
  background: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 7px;
  height: 50px;
  border-style: none;
}
.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 25px;
}
.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  margin-left: 5px !important;
  margin-top: 2px !important;
}

.workout-main-div {
  display: grid;
  grid-template-columns: 2fr 6fr;
}
.select-dropdown .ant-select-arrow {
  color: #0a364b !important;
}
.siderMenu {
  margin-top: 3rem !important;
}
.sider-icon {
  height: 24px;
  width: 24px;
}

.dashboard-workout-main-div {
  height: 100vh;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 0px;
}

.dashboard-group-main-div {
  height: 100vh;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 0px;
}

.header-logo {
  height: 55px;
  cursor: pointer;
}
.slick-dots {
  display: flex !important;
  height: 10px;
  width: 100%;
  margin-left: 10px;
  justify-content: flex-end !important;
  top: -22% !important;
}
.header-burger-btn {
  height: 75px;
  cursor: pointer;
  margin-left: 25px;
}
.ant-drawer .ant-drawer-mask {
  position: absolute;
  inset: 0;
  z-index: 1000;
  background: none;
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  background: transparent;
  border-radius: 0px 20px 20px 0px;
}
@media (max-width: 768px) {
  .header {
    padding-inline: 10px;
  }
  //=====================Group responsiveness==============
  .dashboard-group-main-div {
    height: 100vh;
    display: grid;

    grid-template-columns: 6.5fr 9fr !important;
  }
  //=====================Group responsiveness==============
  .dashboard-group-main-div {
    height: 100vh;
    display: grid;
    grid-template-columns: 55% 45%;
    grid-gap: 0px;
  }
  .activity-scroll-div {
    margin-right: 0px !important;
  }
  //===============workout responsiveness================
  .workout-main-div {
    display: grid;
    grid-template-columns: 3fr 6fr;
    margin-left: 20px;
  }
  .dashboard-workout-main-div {
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0px;
  }

  .exercise-title-desc {
    font-family: "Poppins";
    font-size: 14px;
    color: #526b78;
    margin-bottom: 0px;
  }

  .assign-workout-btn {
    height: 50px;
    width: auto !important;
  }
  //==========sider responsiveness=========
  .siderMenu {
    margin-top: 1.5rem !important;
  }
  .ant-layout-sider {
    display: none !important;
  }
  .header-logo-div {
    display: none !important;
  }
  .drawer-logo {
    display: flex;
    align-items: center;
    height: fit-content;
    border-radius: 0px 29px 0px 0px;
    width: 182px;
    padding-top: 20px;
    background: white;
    justify-content: center;
    position: fixed;
    top: -5px;
    left: 0px;
    z-index: 2;
  }
  .ant-menu-item {
    padding-left: 16px !important;
  }
  .ant-menu-inline .ant-menu-item {
    height: 35px !important;
    width: 137.5px !important;
    font-size: 12px !important;
  }
  .ant-drawer .ant-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 20px;
    overflow: auto;
  }
  .sider-icon {
    height: 15px;
    width: 15px;
  }
}
@media (min-width: 769px) {
  .ant-drawer-left {
    display: none !important;
  }
}
@media (width: 1024px) {
  .dashboard-workout-main-div {
    height: 100vh;
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 0px;
  }
  .workout-exercise-card {
    background: #eef8fe;
    border-radius: 10px;
    padding: 15px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 35% 65%;
  }
  .save-workout-btn {
    width: 40%;
  }
}
.assign-workout-btn {
  height: 50px;
  width: auto !important;
}

@media (max-width: 1024px) {
  .activity-burger-btn {
    height: 45px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);
  }
  .activity-scroll-div {
    margin-right: 0px !important;
    height: 92vh;
  }
  .dashboard-main-div {
    height: 100vh;
    display: grid;
    grid-template-columns: none;
  }
  .activity-feed-div {
    display: none;
  }
  .ant-drawer-right > .ant-drawer-content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px 0px 0px 20px;
    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
      -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 1025px) {
  .ant-drawer-right {
    display: none !important;
  }
  .activity-burger-btn {
    display: none;
  }
}
