//Assign-Modal
.assign-workout-modal {
  padding: 0px;
  height: 70vh;
  width: max-content;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
}
.ant-tabs .ant-tabs-content-holder {
  overflow-y: auto;
}
.ant-tabs .ant-tabs-content-holder::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
  background-color: transparent;
}
.ant-tabs .ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}
.modal-title {
  font-family: "poppins-semibold";
  font-size: 18px;
  line-height: 51px;
  color: #0a364b;
}
.ant-tabs-ink-bar {
  border-bottom: 2px solid #f79a28;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0a364b !important;
}
.ant-tabs-tab-btn {
  color: rgba(10, 54, 75, 0.5) !important;
}
.select-exercises-container {
  overflow-y: auto;
  flex: 1 1 auto;
}
.modal-footer {
  flex: 0 1 40px;
  align-self: center;
}
.modal-content {
  flex: 1 1 auto;
  overflow-y: hidden;
}
.ant-tabs {
  flex: 1 1 auto;
}
.exercises-cntnr {
  background: #ffffff;
  width: auto;
  height: 100px;
  vertical-align: top;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  margin-right: 10px;
}
.select-exercise-item {
  width: 300px;
  height: auto;
  border: 1px solid rgba(0, 129, 198, 0.1);
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.slct-exrcse-itm-title-container {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #eef8fe;
  padding: 10px;
  margin-bottom: 15px;
}
.slct-exrcse-itm-title {
  font-family: "poppins-semibold";
  font-size: 14px;
  color: #0a364b;
}
.slct-exrcse-itm-description {
  margin-top: 5px;
  font-family: "poppins";
  font-size: 12px;
  color: #0a364b;
}
.select-dropdown {
  margin-bottom: 15px;
  width: 90%;
  background: #ffffff;
  box-shadow: -1px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
}
.ant-select-single {
  height: 50px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-width: 0px;
  height: 50px;
  font-family: "poppins";
}
.ant-select-selection-item {
  height: 50px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 50px;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-width: 0px;
}
.slct-athletes-cntnr {
  background: #ffffff;
  width: auto;
  height: 100px;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: 0px;
}
.add-notes-container {
  width: 400px;
  height: auto;
  border: 1px solid rgba(0, 129, 198, 0.1);
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.add-notes-input {
  background: #ffffff;
  width: 400px;
  height: 100px;
  vertical-align: top;
  margin-right: 10px;
  margin-top: 10px;

  .ant-input {
    font-family: "Poppins";
    font-size: 14px !important;
    margin-left: 5px;
    color: #526b78;
    border-style: none;
    box-shadow: -1px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  }
}
.add-question-container {
  display: flex;
  flex-direction: row;
}
.addbutton {
  background: #f79a28;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  min-width: min-content;
  height: 50px;
  border-style: none;
  border-radius: 7px;
  margin-inline-start: 10px;
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 33px;
}
.question-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
