//login
.main-div {
  display: grid;
  grid-template-columns: 4fr 6fr;
  flex-flow: row wrap;
  box-sizing: border-box;
  align-items: center;

  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  width: 100vw;

  overflow-y: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;

  background-image: url(../images/core/bg-auth.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-des {
  font-family: "Poppins";
  font-size: 18px;
  line-height: 30px;
  color: #526b78;
}

.login-input {
  background: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 7px;
  width: 400px;
  height: 50px;
  border-style: none;
}

.ant-input {
  font-family: "Poppins";
  font-size: 14px !important;
  margin-left: 5px;
  color: #526b78;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #89c2de;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
  color: #89c2de;
}

.forgot-pas-desc {
  font-family: "Poppins";
  font-size: 14px;
  color: #5fa0c0;
  text-align: right;
  cursor: pointer;
}

.authButton {
  background: #f79a28;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  width: 400px;
  height: 50px;
  border-style: none;
  border-radius: 7px;
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 33px;
}

.ant-btn-default:not(:disabled):hover {
  color: #ffffff;
}

.no-accout {
  font-family: "Poppins";
  font-size: 14px !important;
  color: #526b78;
}

//signup

.lets-get-started {
  font-family: "poppins-semibold";
  font-size: 26px;
  line-height: 51px;
  color: #0a364b;
}

.sign-up-as {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 27px;
  color: #0a364b;
}

.radio-value {
  font-family: "poppins-semibold";
  font-size: 14px;
  line-height: 26px;
}

.yellow-underline {
  color: #f79a28;
  text-decoration: underline;
  cursor: pointer;
}

//
.forgot-heading {
  font-family: "poppins-semibold";
  font-size: 34px;
  line-height: 51px;
  color: #0a364b;
}

.otp-input {
  display: flex;
  justify-content: space-around;
}

.otp-input > input {
  width: 60px !important;
  height: 60px !important;
  text-align: center;
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  border-style: none;
  font-family: "poppins-semibold";
  font-size: 30px;
  color: #007cba;
  margin: 0 !important;
}

//Header
.header {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 6.5fr 9fr;
  height: 80px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.noti-div {
  background: rgba(57, 126, 193, 0.1);
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.input-header {
  height: 50px;
  width: 400px;
  background: #ffffff;
  box-shadow: 4px 4px 17px rgba(38, 51, 77, 0.15);
  border-radius: 30px;
  border-style: none;

  .ant-input {
    font-family: "Poppins";
    font-size: 14px !important;
    margin-left: 5px;
    color: #526b78;
  }
}

.auth-logo {
  width: 380px;
}

//login

@media (max-width: 1020px) {
  .strech-man-div {
    display: none;
    background-color: #0081c6;
  }
  .main-div {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    background-image: none;
  }

  .auth-form-inner {
    margin: 30px;
  }
}

@media (max-width: 320px) {
  .auth-form-inner {
    margin: 0 !important;
  }
  .auth-form-div {
    margin: 0 !important;
  }
}

@media (max-width: 425px) {
  .auth-form-div {
    width: 100% !important;
  }

  .auth-form-inner {
    width: 90% !important;
  }

  .auth-logo {
    width: 100%;
  }

  .login-input {
    width: 100% !important;
  }

  .authButton {
    width: 100%;
  }
  .login-des {
    margin: 0 !important;
  }
}
