//group

.join-btn {
  position: unset;
  height: 40px;
  background: #0081c6;
  box-shadow: 1px 1px 2px rgba(71, 42, 6, 0.1);
  border-radius: 10px;
  font-family: "poppins-semibold";
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.group-card {
  background: #eef8fe;
  border-radius: 10px;
  padding: 26px 22px;
  min-width: 240px;
  max-width: 350px;
  max-height: 200px;
  overflow: hidden;
  cursor: pointer;
}

.group-heading {
  font-family: "poppins-semibold";
  font-size: 16px;
  color: #0a364b;
}

.created {
  font-family: "Poppins";
  font-size: 12px;
  color: #007cba;
}

.tips-heading {
  font-family: "poppins-semibold";
  font-size: 20px;
  line-height: 30px;
  color: #186c95;
}

.invite-code-heading {
  font-family: "poppins-semibold";
  font-size: 14px;
  line-height: 21px;
  color: #222c34;
}

.invite-code {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  color: #186c95;
  text-align: center;
  transition: all 0.1s ease-in-out;
}

.invite-code:hover {
  cursor: pointer;
  font-size: 17px;
}

.tips-desc {
  font-family: "Poppins";
  font-size: 15px;
  line-height: 18px;
  color: #526b78;
}

.coaches {
  font-family: "poppins-semibold";
  font-size: 20px;
  line-height: 30px;
  color: #222c34;
}

//join-group

.join-group-heading {
  font-family: "poppins-semibold";
  font-size: 18px;

  color: #0b0b0b;
}
.join-group-form-div {
  background: #eef8fe;
  border-radius: 10px;
  width: 80%;
  padding: 25px;
}

.join-group-field {
  color: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  width: 65%;
  height: 45px;
  border-radius: 10px;
  border-style: none;
  color: #526b78;

  .ant-input {
    font-family: "Poppins";
    font-size: 14px !important;
  }
}

.join-group-btn {
  border-radius: 10px;
  background: #0081c6;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  width: 30%;
  height: 45px;
  font-family: "poppins";
  font-size: 16px;
}

.group-created-on {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  color: #007cba;
}

.group-type {
  font-family: "Poppins";
  font-size: 15px;
  line-height: 22px;
  color: #0a364b;
}
