//Athlete Profile
.athlete-profile-name {
  font-family: "poppins-semibold";
  font-size: 20px;
  color: #0b0b0b;
}

.completed-on {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  color: #007cba;
}

.profile-workout {
  min-height: 150px;
  padding: 25px;
  background: #eef8fe;
  border-radius: 10px;
}

.blue-circle-bg {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: rgba(235, 242, 249, 1);
}

.athlete-radio-group .ant-radio-group {
  width: 120% !important;
  border: 1px solid #e7f6ff;
  border-radius: 25px;

  .ant-radio-button-wrapper {
    height: 45px;
    text-align: center;
    color: #858585;
    font-family: "Poppins-semibold";
    font-size: 14px;
    background: #ffffff;
    width: 33.3%;
    padding-top: 7px;
    cursor: pointer;
    border-style: none;

    .span {
      margin-top: 25px !important;
    }
  }
}

.athlete-radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 25px !important;
  border-style: none;
  background-color: transparent;
}

.athlete-radio-group .ant-radio-button-wrapper:last-child {
  border-radius: 25px !important;
  border-style: none;
  background-color: transparent;
}

.athlete-radio-group
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #0081c6;
  border-radius: 25px !important;
}

.athlete-radio-group .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.ant-picker {
  border-color: transparent;
}

.ant-picker:hover {
  border-color: transparent;
}

.ant-picker-input {
  display: none !important;
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #f79a28;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #f79a28;
  }
}

 .ant-picker-date-panel
 {
  .ant-picker-content th:first-child {
    color: #f79a28;
  }
  .ant-picker-content th:last-child {
    color: #f79a28;
  }
  .ant-picker-content thead {
    font-family:'Poppins-semibold';
    text-transform: uppercase;
  }
 } 

.month-day-in-english {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
}

.day-in-no {
  font-family: "Poppins-semibold";
  font-size: 20px;
}

.selected-date {
  background: #f79a28;
  border-radius: 10px;
  color: #ffffff !important;
}

//assign-workout-detail
.workout-heading-detail {
  font-family: "poppins-semibold";
  font-size: 18px;
  line-height: 27px;
  color: #0a364b;
}

.notes {
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0a364b;
}

.notes-detail {
  font-family: "poppins";
  font-size: 13px;
  line-height: 24px;
  color: #0a364b;
}

.workout-exercise-card {
  background: #eef8fe;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 35% 65%;
  cursor: pointer;
}

.workout-exercise-card-in-athelete-profile {
  background: #eef8fe;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 35% 55% 5%;
}
.adjustment-and-sets {
  font-family: "Poppins";
  font-size: 10px;
  line-height: 15px;
  text-align: justify;
  text-transform: capitalize;
  color: #0a364b;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}

.assign-workout-btn {
  height: 50px;
  width: 20%;
}

//Favourite

.favourite-radio-group .ant-radio-group {
  width: 70% !important;
  border: 1px solid #e7f6ff;
  border-radius: 25px;

  .ant-radio-button-wrapper {
    height: 45px;
    text-align: center;
    color: #858585;
    font-family: "Poppins-semibold";
    font-size: 14px;
    background: #ffffff;
    width: 50%;
    padding-top: 7px;
    cursor: pointer;
    border-style: none;

    .span {
      margin-top: 25px !important;
    }
  }
}

.favourite-radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 25px !important;
  border-style: none;
  background-color: transparent;
}

.favourite-radio-group .ant-radio-button-wrapper:last-child {
  border-radius: 25px !important;
  border-style: none;
  background-color: transparent;
}

.favourite-radio-group
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #f89728;
  border-radius: 25px !important;
}

.favourite-radio-group
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked:hover {
  color: #fff;
  background-color: #f89728;
  border-radius: 25px !important;
}

.favourite-radio-group .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.disabilities-div {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgba(156, 187, 204, 0.25);
}

.disabilities-text {
  font-family: "Poppins";
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #567e95;
}
